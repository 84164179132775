import { intlModule } from '@chedri/base';
import Axios from 'axios';
import DataId from 'components/DataId';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _maxBy from 'lodash/maxBy';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { FormattedNumber, injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  getVariantCopyAmountValue,
  getVariantLeftCampaignBudget,
  getVariantLeftUserBudget,
  getVariantNetPrice,
} from 'helpers/cart';
import { getValueFromCartItem } from 'helpers/coupon';
import { checkEditionErrors } from 'helpers/edition';
import { getGrossFromNet } from 'helpers/financial';
import { CartItem } from 'helpers/proptypes';
import { vatValueFromNet } from 'helpers/vat';
import { cartRemoveEdition, cartUpdateItem } from 'actions/CartActions';
import { getNameInCurrentLanguage } from 'helpers/intl';
import Confirmation from 'components/forms/Confirmation';
import FormInput from 'components/forms/FormInput';
import IconCheckbox from 'components/forms/IconCheckbox';
import IconRadioGroup from 'components/forms/IconRadioGroup';
import TableDropdown from 'components/forms/TableDropdown';
import TextareaEditor from 'components/forms/TextareaEditor';
import Icon from '../../Icon';
import Severity from '../../validation/Severity';
import Validation from '../../validation/Validation';
import ValidationAlert from '../../validation/ValidationAlert';
import Address from '../contacts/Address';
import Headline from '../Headline';
import Properties from '../Properties';
import ValueAddedTax from '../ValueAddedTax';
import CartWizardStepActions from './CartWizardStepActions';
import CartWizardStepBody from './CartWizardStepBody';
import CartWizardStepBudgetHint from './CartWizardStepBudgetHint';
import CartWizardStepCompactInfo from './CartWizardStepCompactInfo';
import CartWizardStepContext from './CartWizardStepContext';
import CartWizardStepCoupon from './CartWizardStepCoupon';
import CartWizardStepFooter from './CartWizardStepFooter';
import CartWizardStepFrame from './CartWizardStepFrame';
import CartWizardStepHeader from './CartWizardStepHeader';
import CartWizardStepMediaNavigator from './CartWizardStepMediaNavigator';
import PerforationOptionsSelector from './PerforationOptionsSelector';
import TextileSizesSelector from './TextileSizesSelector';

CartWizardStep.propTypes = {
  number: PropTypes.number.isRequired,
  cartItem: CartItem.isRequired,
  onConfirm: PropTypes.func,
};

CartWizardStep.defaultProps = {
  onConfirm: () => undefined,
};

const validators = [
  function isManualDataCheckMarked(data, messages) {
    const valid = data && (data.item.type === 'itemPrint' || data.is_manual_data_check_requested);
    return {
      key: 'manual_data_check',
      info: {
        valid,
        severity: Severity.Warning,
        helpText: messages.cart_no_warranty_without_manual_data_check,
      },
    };
  },
  function isDomainHostingChecked(data, messages) {
    const valid = data && (!data.is_wants_domain_hosting || data.desired_domain !== '');
    return {
      key: 'domain_hosting',
      info: {
        valid,
        severity: Severity.Warning,
        helpText: messages.cart_missing_desired_domain,
      },
    };
  },
  function isCampaignBudgetExceeded(data, messages) {
    const valid = getVariantLeftCampaignBudget(data.variant, data) >= 0;
    return {
      key: 'campaign_budget',
      info: {
        valid,
        severity: Severity.Warning,
        helpText: messages.campaign_budget_exceed_warning,
      },
    };
  },
  function isUserBudgetExceeded(data, messages) {
    const valid = getVariantLeftUserBudget(data.variant, data) >= 0;
    return {
      key: 'user_budget',
      info: {
        valid,
        severity: Severity.Warning,
        helpText: messages.user_budget_exceed_warning,
      },
    };
  },
];

// function createErrorRule() {
//   return {
//     name: 'error',
//     verify: function errorRule() {
//       return {
//         someerror: {
//           valid: false,
//           severity: Severity.Error,
//           messageKey: 'geolocation_error',
//         },
//       };
//     },
//   };
// }

function CartWizardStep({ intl, number, cartItem, onConfirm, projectConfig }) {
  const dispatch = useDispatch();
  const messages = useSelector(intlModule.selectors.getMessages);
  const variants = useSelector(state => _get(state, ['dataId', 'itemVariants', cartItem.id, 'data', 'list'], null));
  const isVariantsPending = useSelector(state =>
    _get(state, ['dataId', 'itemVariants', cartItem.id, 'pending'], false)
  );

  const isCartPending = useSelector(state => !!state.cart.pending);
  const isCartOffersInvoicePaymentOption = useSelector(state =>
    (state.cart.available_payment_options ?? []).includes('bill')
  );

  const [coupon, setCoupon] = useState(cartItem.coupon);
  const [editableItem, setEditableItem] = useState(cartItem.item);
  const [editionList, setEditionList] = useState([]);
  const [editionListColumns, setEditionListColumns] = useState([]);
  const [editionColumns, setEditionColumns] = useState([]);
  const [validationResults, setValidationResults] = useState([]);
  const [domainValidationResult, setDomainValidationResult] = useState({ available: null, desired_domain: '' });

  const itemBudget = Math.min(
    cartItem.campaign_budget - cartItem.campaign_running_budget,
    cartItem.user_budget - cartItem.user_running_budget
  );
  const shippingAddresses =
    cartItem.split_shipping && cartItem.split_shipping.length
      ? cartItem.split_shipping
      : cartItem.shipping_address
      ? [{ id: cartItem.id, address: cartItem.shipping_address }]
      : [];

  const checkDomainAvailability = cartItem => {
    const inputFieldValue = document.getElementsByName('desired_domain')[0].value;
    let checkDomain = cartItem.desired_domain;
    if (checkDomain !== inputFieldValue) {
      checkDomain = inputFieldValue;
    }
    Axios.get('/api/route53/checkdomainavailability.json?domain=' + checkDomain).then(response => {
      const isAvailable = response.data.Availability === 'AVAILABLE';
      if (isAvailable) {
        confirmAlert({
          title: `${checkDomain}`,
          message: messages.cart_domain + ' ' + checkDomain + ' ' + messages.cart_domain_available,
          buttons: [
            {
              label: 'OK',
              onClick: () => undefined,
            },
          ],
          closeOnEscape: true,
          closeOnClickOutside: true,
        });
        setDomainValidationResult({ available: true, desired_domain: checkDomain });
      } else {
        confirmAlert({
          title: messages.cart_domain_not_available_title,
          message: messages.cart_domain + ' ' + checkDomain + ' ' + messages.cart_domain_not_available,
          buttons: [
            {
              label: 'OK',
              onClick: () => undefined,
            },
          ],
          closeOnEscape: true,
          closeOnClickOutside: true,
        });
        setDomainValidationResult({ available: false, desired_domain: '' });
      }
    });
  };

  const change = useCallback(
    data => {
      setEditableItem({ ...editableItem, ...data });
    },
    [editableItem, setEditableItem]
  );

  const changedCartItemValue = (value, name) => {
    dispatch(cartUpdateItem(cartItem, { [name]: value }));
  };

  useEffect(() => {
    setEditableItem(_cloneDeep(cartItem));
    setEditionColumns([
      { key: 'amount', messageKey: 'count' },
      { key: 'delivery' },
      { key: 'specialOffer', messageKey: 'special_offer' },
      { key: 'net' },
      { key: 'vatValue', messageKey: 'vat_short' },
      { key: 'gross' },
    ]);

    setValidationResults([
      ...validators.map(checkFn => checkFn(cartItem, messages)).filter(item => !item.info.valid),
      ...checkEditionErrors(cartItem.edition, messages).map(err => ({
        key: err.type,
        info: {
          valid: false,
          severity: Severity.Error,
          helpText: (
            <>
              {err.info}{' '}
              <Link to={`/de/editor/${cartItem.edition.id}`} className="text-primary b-b">
                {messages.cart_edit_now}
              </Link>
            </>
          ),
        },
      })),
    ]);
    if (cartItem.item && Array.isArray(cartItem.item.available_coupons) && cartItem.item.available_coupons.length > 0) {
      const coupons = cartItem.item.available_coupons
        // Filter coupons with no value
        .filter(
          coupon =>
            (coupon.type === 'percentage' && coupon.percent_discount > 0) ||
            (coupon.type === 'monetary' && coupon.value_discount > 0)
        )
        // Calculate the possible discount
        .map((coupon, index) => ({ value: getValueFromCartItem(coupon, cartItem, true), index }))
        // The highest discount must be the first entry
        .sort((a, b) => b.value - a.value);
      // Set the coupon with the highest discount
      if (coupons.length > 0) {
        setCoupon(cartItem.item.available_coupons[coupons[0].index]);
      } else {
        setCoupon(undefined);
      }
    }
  }, [cartItem]);

  useEffect(() => {
    // const fromKey = `${cartItem.shipping_type}_from`;
    const toKey = `${cartItem.shipping_type}_to`;
    if (variants) {
      setEditionList(
        variants.map(variant => {
          const amount = getVariantCopyAmountValue(variant);

          // const deliveryMin = parseInt((variant.delivery_times && variant.delivery_times[fromKey]) || 0, 10);
          const deliveryMax = parseInt((variant.delivery_times && variant.delivery_times[toKey]) || 0, 10);

          const netPrice = getVariantNetPrice(variant, cartItem);
          const vatRate = variant.pricing.vat_rate;

          const netAmount = netPrice ?? 0;
          let grossAmount = 0;
          let vatValue = 0;
          if (netAmount !== 0) {
            vatValue = vatValueFromNet(netAmount, vatRate);
            grossAmount = netAmount + vatValue;
          }
          const isExceedingBudget =
            getVariantLeftCampaignBudget(variant, cartItem) < 0 || getVariantLeftUserBudget(variant, cartItem) < 0;
          return {
            amount: (
              <div>
                {`${amount} ${messages.uom.piece} `}
                {isExceedingBudget ? <Icon name="fa-euro-sign" solid iconClassName="danger" /> : null}
              </div>
            ),
            delivery: `${deliveryMax} ${messages.workdays}`, // `${deliveryMin}-${deliveryMax} ${messages.workdays}`,
            netValue: netAmount,
            isExceedingBudget,
            net: <FormattedNumber value={netAmount} style="currency" currency={variant.pricing.currency} />,
            gross: <FormattedNumber value={grossAmount} style="currency" currency={variant.pricing.currency} />,
            vatValue: <FormattedNumber value={vatValue} style="currency" currency={variant.pricing.currency} />,
            value: amount,
            variant,
          };
        })
      );
    }

    setEditionListColumns(
      editionColumns.map(column => {
        const { key, messageKey, render } = column;

        return { key, label: messages[messageKey] || messages[key], render };
      })
    );
  }, [cartItem, variants, itemBudget]);

  const changedShippingType = useCallback(shippingType => {
    if (
      cartItem.variant &&
      !cartItem.variant.pricing[shippingType !== 'standard' ? `${shippingType}_price` : 'price']
    ) {
      confirmAlert({
        title: messages.cart_production_type_change_warning_title,
        message: messages.cart_production_type_change_warning.replace(
          /\{\{(shippingType)\}\}/gi,
          messages[`production_${shippingType}`]
        ),
        buttons: [
          {
            label: messages.yes,
            onClick: () => {
              changedCartItemValue(shippingType, 'shipping_type');
            },
          },
          {
            label: messages.no,
            onClick: () => undefined,
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    } else {
      changedCartItemValue(shippingType, 'shipping_type');
    }
  });

  const domainAvailability = useCallback(() => {
    checkDomainAvailability(cartItem);
  });

  const remove = useCallback(() => {
    confirmAlert({
      title: messages.are_you_sure,
      // message: '',
      buttons: [
        {
          label: messages.yes,
          onClick: () => {
            dispatch(cartRemoveEdition(cartItem.edition));
          },
        },
        {
          label: messages.no,
          onClick: () => undefined,
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  });

  const configure = useCallback(() => {
    dispatch(
      cartUpdateItem(cartItem, {
        is_configuration_finished: false,
      })
    );
  });

  // TODO VAT rate need's to be data driven but currently hard-coded ... also
  //  their is the need under some circumstances like in case of item reduced
  //  vat rate to differentiate because of could be ordering extra services
  //  that have to be calculated with default tax rate.
  cartItem.vat = 19.0;

  const isItemAllowsReducedVatRate =
    !!cartItem?.is_reduced_vat_rate_check_available && isCartOffersInvoicePaymentOption;

  let productSelection = [
    { value: 'print_production', icon: 'fa-print', label: messages.print_production },
    /* { value: 'pdf_only', icon: 'fa-download', label: messages.pdf_download } */
  ];

  productSelection.push({ value: 'pdf_only', icon: 'fa-download', label: messages.pdf_download });

  if (!projectConfig?.data?.sell_download_pdf) {
    productSelection = [{ value: 'print_production', icon: 'fa-print', label: messages.print_production }];
  }

  const appIntl = useSelector(intlModule.selectors.getIntl);
  const itemName =
    typeof cartItem?.name === 'object' ? getNameInCurrentLanguage(cartItem?.name, appIntl) : cartItem?.name;

  return (
    <CartWizardStepContext.Provider
      value={{
        item: editableItem,
        origin: cartItem,
        change,
        confirm: onConfirm,
        remove,
        configure,
        domainAvailability,
      }}
    >
      <DataId
        dataIdName="itemVariants"
        dataIdId={cartItem.id}
        url={'/api/items/' + cartItem.item.id + '/variants.json'}
        requestData={{
          filters: { shippingType: cartItem.shipping_type },
          filter: { Seitenzahl: cartItem.edition.pages.length },
        }}
      />
      <Validation rules={[]} validateOnValueChange={cartItem}>
        <div className="wizard-step">
          <CartWizardStep.Frame>
            <CartWizardStep.Header
              number={number}
              name={itemName}
              label={cartItem.label}
              campaign={cartItem.campaign && cartItem.campaign.title}
              lastModified={new Date(cartItem.lastModified)}
              lastStored={new Date(cartItem.lastStored)}
              validationResult={(_maxBy(validationResults, 'info.severity') || {}).info}
              messages={messages}
            >
              {coupon && <CartWizardStep.Coupon coupon={coupon} />}
            </CartWizardStep.Header>
            {cartItem.is_configuration_finished ? null : (
              <>
                <CartWizardStep.Body>
                  <CartWizardStep.MediaNavigator edition={cartItem.edition} />
                  <h3>{messages.product_details}</h3>
                  <Properties data={cartItem.details} />
                </CartWizardStep.Body>
                <CartWizardStep.Footer>
                  {/* <ValidationAlerts /> */}
                  {validationResults.length && !cartItem?.edition?.template?.direct_ordering ? (
                    <div className="validation-alerts">
                      {validationResults.map(entry => {
                        return <ValidationAlert key={entry.key} property="info" validationResult={entry} />;
                      })}
                    </div>
                  ) : null}

                  <CartWizardStepBudgetHint
                    title={messages.cart_budget_campaign}
                    availableBudgetLabel={messages.cart_remaining_campaign_budget}
                    availableBudget={cartItem.campaign_budget - cartItem.campaign_running_budget}
                    leftBudgetLabel={messages.cart_remaining_campaign_budget_after_order}
                    leftBudget={getVariantLeftCampaignBudget(cartItem.variant, cartItem)}
                    currency={cartItem.currency}
                  />
                  <CartWizardStepBudgetHint
                    title={messages.cart_budget_user}
                    availableBudgetLabel={messages.cart_remaining_user_budget}
                    availableBudget={cartItem.user_budget - cartItem.user_running_budget}
                    leftBudgetLabel={messages.cart_remaining_user_budget_after_order}
                    leftBudget={getVariantLeftUserBudget(cartItem.variant, cartItem)}
                    currency={cartItem.currency}
                  />
                </CartWizardStep.Footer>
              </>
            )}
          </CartWizardStep.Frame>
          <CartWizardStep.Frame>
            <CartWizardStep.Body pending={isCartPending}>
              {cartItem.is_configuration_finished ? (
                <CartWizardStepCompactInfo cartItem={cartItem} />
              ) : (
                <>
                  {cartItem.item.type === 'itemWebsite' ? (
                    <>
                      <Row>
                        <Col xs={8}>
                          <Headline description={messages.type_of_order_website}>{messages.type_of_order}</Headline>
                        </Col>
                        <Col xs={4}>
                          <ValueAddedTax
                            net={cartItem.price}
                            gross={cartItem.gross}
                            vat={cartItem.vat}
                            currency={cartItem.currency}
                            webPdfPrice={cartItem.is_web_pdf_requested ? cartItem.web_pdf_price : 0}
                            manualDataCheckPrice={
                              cartItem.is_manual_data_check_requested ? cartItem.manual_data_check_price : 0
                            }
                            isItemAllowsReducedVatRate={isItemAllowsReducedVatRate}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col xs={8}>
                          <Headline description={messages.type_of_order_choices}>{messages.type_of_order}</Headline>
                        </Col>
                        <Col xs={4}>
                          <ValueAddedTax
                            pending="isVariantsPending"
                            net={cartItem.price}
                            gross={cartItem.gross}
                            vat={cartItem.vat}
                            currency={cartItem.currency}
                            webPdfPrice={cartItem.is_web_pdf_requested ? cartItem.web_pdf_price : 0}
                            manualDataCheckPrice={
                              cartItem.is_manual_data_check_requested ? cartItem.manual_data_check_price : 0
                            }
                            appliedProductGroupDiscount={cartItem.applied_product_group_discount}
                            isItemAllowsReducedVatRate={isItemAllowsReducedVatRate}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <IconRadioGroup
                            name="print_type"
                            inline
                            variant="primary"
                            data={productSelection}
                            value={cartItem ? cartItem.print_type || '' : ''}
                            onChange={(value, name) => changedCartItemValue(value, name)}
                            pending={isVariantsPending}
                          />
                        </Col>
                      </Row>
                      {cartItem.print_type === 'print_production' && (
                        <>
                          <Row>
                            <Col>
                              <Headline description={messages.production_time_description}>
                                {messages.production_time}
                              </Headline>
                              <IconRadioGroup
                                name="shipping_type"
                                inline
                                variant="primary"
                                data={[
                                  { value: 'standard', icon: 'fa-truck', label: messages.production_standard },
                                  { value: 'express', icon: 'fa-truck', label: messages.production_express },
                                  { value: 'overnight', icon: 'fa-truck', label: messages.production_overnight },
                                ].filter(
                                  ({ value }) =>
                                    cartItem.available_shipping_types &&
                                    cartItem.available_shipping_types.includes(value)
                                )}
                                value={cartItem ? cartItem.shipping_type || '' : ''}
                                onChange={changedShippingType}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Headline description={messages.choose_edition_description}>
                                {messages.choose_edition}
                              </Headline>
                              <TableDropdown
                                name="variant"
                                icon="fa-bars"
                                label={messages.number_of_copies}
                                variant="primary"
                                value={
                                  isVariantsPending
                                    ? ''
                                    : cartItem
                                    ? getVariantCopyAmountValue(cartItem.variant) || ''
                                    : ''
                                }
                                onChange={({ variant }, name) => changedCartItemValue(variant, name)}
                                data={editionList}
                                columns={editionListColumns}
                                rowKey={row => `amount_${row.value}`}
                                rowClassname={row =>
                                  row.isExceedingBudget
                                    ? 'exceeded'
                                    : // : row.netValue >= coupon.min_order_value
                                      // ? 'discount'
                                      ''
                                }
                                selectLabel={row => row.amount}
                                pending={isVariantsPending}
                                ownClassName="priceList"
                              />
                            </Col>
                          </Row>
                          {PerforationOptionsSelector({ cartItem, intl, changedCartItemValue })}
                          {TextileSizesSelector({ cartItem, intl, changedCartItemValue })}
                        </>
                      )}
                    </>
                  )}
                  <Row>
                    <Col />
                  </Row>
                  {cartItem.item.type === 'itemWebsite' ? (
                    <>
                      <Row>
                        <Col xs={12}>
                          <Headline description={messages.more_options_description}>{messages.more_options}</Headline>
                        </Col>
                        <Col xs={12} className="order-options">
                          <div className="order-options__left">
                            <IconCheckbox
                              name="is_wants_domain_hosting"
                              icon="bg-green fa-globe"
                              label={`${messages.domain_hosting} ( ${messages.annual.toLowerCase()} )`}
                              description={`${messages.net_amount}: ${intl.formatNumber(
                                cartItem.domain_hosting_net_amount,
                                {
                                  style: 'currency',
                                  currency: cartItem.currency,
                                }
                              )} / ${messages.gross_amount}: ${intl.formatNumber(
                                getGrossFromNet(cartItem.domain_hosting_net_amount, cartItem.vat),
                                {
                                  style: 'currency',
                                  currency: cartItem.currency,
                                }
                              )}`}
                              value
                              checked={cartItem ? !!cartItem.is_wants_domain_hosting : false}
                              onChange={(value, name) => changedCartItemValue(!!value, name)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="order-options">
                          {!cartItem.is_wants_domain_hosting ? null : (
                            <div className="order-options__left">
                              <FormInput
                                name="desired_domain"
                                label={`${messages.desired_domain}`}
                                type="text"
                                style={
                                  domainValidationResult.available
                                    ? { background: '#b2ce4d', color: '#FFFFFF', border: '1px solid #b2ce4d' }
                                    : domainValidationResult.available === false
                                    ? { background: '#cd1718', color: '#FFFFFF', border: '1px solid #cd1718' }
                                    : null
                                }
                                value={cartItem.desired_domain ?? ''}
                                onBlur={(name, value) => changedCartItemValue(value, name)}
                              />
                              <button type="button" className="btn btn-success btn-sm">
                                {messages.save}
                              </button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : !(cartItem.print_type === 'pdf_only' || cartItem.print_type === 'print_production') ? null : (
                    <>
                      <Row>
                        <Col xs={12}>
                          <Headline description={messages.more_options_description}>{messages.more_options}</Headline>
                        </Col>
                        <Col xs={12} className="order-options">
                          <div className="order-options__left">
                            {cartItem?.edition?.item?.pricing?.dataproof_base_price > 0 ||
                            cartItem?.edition?.item?.pricing?.new_dataproof_base_price > 0 ? (
                              <IconCheckbox
                                name="is_manual_data_check_requested"
                                icon="fa-eye"
                                label={messages.manual_data_review}
                                description={`${messages.net}: ${intl.formatNumber(cartItem.manual_data_check_price, {
                                  style: 'currency',
                                  currency: cartItem.variant ? cartItem.variant.pricing.currency : cartItem.currency,
                                })} / ${messages.gross}: ${intl.formatNumber(
                                  getGrossFromNet(cartItem.manual_data_check_price, cartItem.vat),
                                  {
                                    style: 'currency',
                                    currency: cartItem.variant ? cartItem.variant.pricing.currency : cartItem.currency,
                                  }
                                )}`}
                                variant="success"
                                value
                                checked={cartItem ? !!cartItem.is_manual_data_check_requested : false}
                                onChange={(value, name) => changedCartItemValue(!!value, name)}
                              />
                            ) : (
                              <></>
                            )}
                            {cartItem.is_manual_data_check_requested ? (
                              <TextareaEditor
                                name="manual_data_check_request_comment"
                                value={cartItem.manual_data_check_request_comment}
                                onChange={value => changedCartItemValue(value, 'manual_data_check_request_comment')}
                                placeholder={messages.dataproof_textarea}
                              />
                            ) : null}

                            <IconCheckbox
                              className="m-t-10"
                              name="is_web_pdf_requested"
                              icon="fa-file-pdf"
                              label={messages.web_enabled_pdf}
                              description={`${messages.net}: ${intl.formatNumber(cartItem.web_pdf_price, {
                                style: 'currency',
                                currency: cartItem.variant ? cartItem.variant.pricing.currency : cartItem.currency,
                              })} / ${messages.gross}: ${intl.formatNumber(
                                getGrossFromNet(cartItem.web_pdf_price, cartItem.vat),
                                {
                                  style: 'currency',
                                  currency: cartItem.variant ? cartItem.variant.pricing.currency : cartItem.currency,
                                }
                              )}`}
                              variant="success"
                              value
                              checked={cartItem ? !!cartItem.is_web_pdf_requested : false}
                              onChange={(value, name) => changedCartItemValue(!!value, name)}
                            />
                            {cartItem.is_reduced_vat_rate_check_available ? (
                              <Confirmation
                                name="is_reduced_vat_rate_check_requested"
                                value
                                checked={cartItem ? !!cartItem.is_reduced_vat_rate_check_requested || false : false}
                                onChange={(value, name) => changedCartItemValue(!!value, name)}
                              >
                                {messages.cart_order_check}
                              </Confirmation>
                            ) : null}
                          </div>
                          {cartItem.print_type === 'pdf_only' || cartItem.item.type === 'itemWebsite' ? null : (
                            <>
                              <div className="order-options__right">
                                {Array.isArray(shippingAddresses) && shippingAddresses.length ? (
                                  <div className="contacts">
                                    {shippingAddresses.map(({ id, address, quantity }, idx) => (
                                      <div key={id} className="contact">
                                        <div className="headline">
                                          {messages.deliver_adress}
                                          {`${idx > 0 ? ' #' + (idx + 1) : ''}`}
                                        </div>
                                        {address?.contact?.company ? (
                                          <div className="contact-company">{address?.contact?.company}</div>
                                        ) : (
                                          ''
                                        )}
                                        <div className="contact-name">
                                          {address?.contact?.first_name ? address.contact.first_name + ' ' : ''}
                                          {address?.contact?.last_name ? address.contact.last_name : ''}
                                        </div>
                                        <Address data={address.address} />
                                        {quantity && (
                                          <div className="quantity">
                                            ({quantity} {messages.uom.piece})
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col />
                  </Row>
                </>
              )}
            </CartWizardStep.Body>
            <CartWizardStep.Actions cartItem={cartItem} />
          </CartWizardStep.Frame>
        </div>
      </Validation>
    </CartWizardStepContext.Provider>
  );
}
CartWizardStep.Frame = CartWizardStepFrame;
CartWizardStep.Coupon = CartWizardStepCoupon;
CartWizardStep.Header = CartWizardStepHeader;
CartWizardStep.Body = CartWizardStepBody;
CartWizardStep.MediaNavigator = CartWizardStepMediaNavigator;
CartWizardStep.Actions = CartWizardStepActions;
CartWizardStep.Footer = CartWizardStepFooter;

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(CartWizardStep)));
