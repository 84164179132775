import React from 'react';
import { connect } from 'react-redux';

import _, { find as _find, forEach as _forEach, cloneDeep as _cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import FormControl from 'react-bootstrap/lib/FormControl';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import SimplePrompt from '../../../components/layout/SimplePrompt';
import { getTimezoneFromState } from '../../../helpers/intl';
import { blockForm, releaseForm } from '../../../actions/ActiveFormsActions';
import { setNavEntriesR } from '../../../actions/EditorActions';
import FormAutocomplete from 'components/forms/FormAutocomplete';
import may from "js/security";
import { ROLE_ROOT_EMPLOYEE, ROLE_SUPERADMIN } from 'js/constants';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class NavigationElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      links: [],
      linksEdit: [],
      showNavEdit: false,
      navUpdated: false,
    };
  }

  setLinks = () => {
    const { linksEdit } = this.state;
    const { id, attributes, updateParam } = this.props;
    let param = '';
    _forEach(linksEdit, (link, i) => {
      param += link[0] + '#aaa#' + link[1] + '#aaa#' + link[2] + (typeof link[3] != 'undefined' ? '#aaa#' + link[3] + '#aaa#' + link[4] : '');
      if (i < linksEdit.length - 1) {
        param += '#cc#';
      }
    });
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ links: linksEdit, showNavEdit: false });
  };

  getLinks = () => {
    const { name: Tag, attributes, params } = this.props;
    const { links } = this.state;
    const { intl: { messages } } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    let nav;
    let setInit = false;
    if (param) {
      const output = param.value.split('#cc#');
      nav = [];
      _forEach(output, (entry, i) => {
        const link = entry.split('#aaa#');
        if (link[0] === '#header') {
          link[0] = '/#header';
        }
        nav.push(link);
      });
    } else {
      nav = [];
      if (attributes['data-name'] === 'socialnav') {
        nav.push({
          0: 'https://twitter.com',
          1: 'Twitter',
          2: 'extern',
          3: 'url',
          4: '',
        });
        nav.push({
          0: 'https://facebook.com',
          1: 'Facebook',
          2: 'extern',
          3: 'url',
          4: '',
        });
      } else {
        nav = [{ 0: '/#header', 1: 'Start', 2: 'undeletable uneditable unmovable' }];
        if (attributes['data-name'] === 'footernav') {
          nav.push({
            0: 'imprint',
            1: messages.editor.imprint,
            2: 'undeletable uneditable unmovable',
            3: 'url',
            4: '',
          });
          nav.push({
            0: 'privacy',
            1: messages.editor.privacy,
            2: 'undeletable uneditable unmovable',
            3: 'url',
            4: '',
          });
        }
      }
      setInit = true;
    }

    this.setState({ links: nav, linksEdit: nav }, () => {
      if (setInit) {
        const { updateParam } = this.props;
        const waitforit = window.setInterval(() => {
          if (typeof updateParam === 'function') {
            this.setLinks();
            window.clearInterval(waitforit);
          }
        }, 100);
      }
    });

    if (param !== undefined && param.name === 'mainnav') {
      let navToActionButton = _cloneDeep(nav);
      let none = ['undefined', '-'];
      navToActionButton.push(none);
      this.props.setNavEntriesR(navToActionButton);
    }
  };

  componentDidUpdate = () => {
    const { navEntry, resetNavEntry, attributes } = this.props;
    const { linksEdit, navUpdated } = this.state;

    if (navEntry && navEntry.length > 0 && attributes['data-name'] === 'mainnav') {
      if (!navUpdated) {
        let newNav = [];
        if (navEntry[1] === 'in') {
          // neuer Eintrag
          if (!navEntry[0][2]) {
            navEntry[0][2] = '';
          }
          newNav = linksEdit;
          newNav.push(navEntry[0]);
        } else {
          // remove Entry
          _forEach(linksEdit, (link, i) => {
            if (!_.includes(link[2], navEntry[0][2])) {
              newNav.push(link);
            }
          });
        }
        this.setState({ linksEdit: newNav, navUpdated: true }, () => {
          this.setLinks();
          resetNavEntry();
        });
        let navToActionButton = _cloneDeep(newNav);
        let none = ['undefined', '-'];
        navToActionButton.push(none);
        this.props.setNavEntriesR(navToActionButton);
      } else {
        this.setState({ navUpdated: false });
      }
    }
  };

  componentDidMount = () => {
    this.getLinks();
  };

  showNavEdit = () => {
    this.setState({ showNavEdit: true });
  };

  closeNavEdit = () => {
    this.setState({ showNavEdit: false });
  };

  removeLink = (e, index) => {
    const { linksEdit } = this.state;
    const {
      intl: { messages },
    } = this.props;
    const nav = linksEdit.slice(0, index);
    const nav2 = linksEdit.slice(index + 1);
    if (nav.length === 0 && nav2.length === 0) {
      let _linksEdit = [
        {
          0: 'https://www.notdefined.de',
          1: 'notDefined',
          2: 'extern',
        },
      ];
      this.setState({ linksEdit: _linksEdit });
    } else {
      this.setState({ linksEdit: nav.concat(nav2) });
    }
  };

  addLink = () => {
    const { linksEdit } = this.state;
    const {
      intl: { messages },
    } = this.props;
    linksEdit.push({
      0: 'https://www.ihrlink.de',
      1: messages.editor.new_menu_item,
      2: 'extern',
      3: 'url',
      4: '',
    });
    this.setState({ linksEdit });
  };

  getPageUrl = pageId => {
    return '/de/website/' + this.props.editionId + '/' + pageId;
  };

  sortLink = (index, type) => {
    const { linksEdit } = this.state;
    const source = linksEdit[index];
    let target;
    if (type === 'up') {
      target = index - 1;
      linksEdit[index] = linksEdit[target];
    } else {
      target = index + 1;
      linksEdit[index] = linksEdit[target];
    }
    linksEdit[target] = source;
    this.setState({ linksEdit });
  };

  changeLink = (e, index, type) => {
    const { linksEdit } = this.state;
    const newLinks = _cloneDeep(linksEdit);
    if (type === 'name') {
      newLinks[index][1] = e.target.value;
    } else if (type === 'link') {
      newLinks[index][0] = e.target.value;
    } else if (type === 'type') {
      newLinks[index][3] = e;
      newLinks[index][0] = '';
      newLinks[index][4] = '';
    } else if (type === 'page') {
      newLinks[index][4] = e;
      newLinks[index][0] = this.getPageUrl(e);
    }
    this.setState({ linksEdit: newLinks });
  };

  render() {
    const { name: Tag, attributes, params, disabled, user } = this.props;
    const { links, linksEdit, showNavEdit } = this.state;
    const {
      intl: { messages },
    } = this.props;
    const types = [{ label: messages.editor.nav_type_url, value: 'url' }, { label: messages.editor.nav_type_page, value: 'page' }];
    const pages = this.props.pages ?? [];
    const availablePages = pages.map(page => {
      return { value: page.id, label: messages.editor.nav_type_page_prefix + ' ' + page.page_number };
    });

    return (
      <>
        <Tag {...attributes} onClick={this.showNavEdit} data-tip={messages.editor.edit_nav} data-for="top">
          <ul className="wrap">
            {links.map((link, i) => {
              return (
                <li data-options={link[2]} data-target={link[0]}>
                  {link[1]}
                </li>
              );
            })}
          </ul>
        </Tag>
        {showNavEdit && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h5>{messages.editor.edit_nav}</h5>
                <Row>
                  <Col md={this.props.isWebsite && availablePages.length > 1 ? 3 : 6}>
                    <h6>{messages.editor.term}</h6>
                  </Col>
                  {this.props.isWebsite && availablePages.length > 1 ? (
                    <Col md={3}>
                      <h6>{messages.editor.nav_type}</h6>
                    </Col>
                  ) : (
                    ''
                  )}
                  <Col md={4}>
                    <h6>{messages.editor.link}</h6>
                  </Col>
                  <Col md={2}>
                    <h6>{messages.editor.actions}</h6>
                  </Col>
                </Row>

                {linksEdit.map((link, i) => {
                  link[3] = link[3] !== 'url' && link[3] !== 'page' ? 'url' : link[3];
                  const disableEdit =
                    may(ROLE_ROOT_EMPLOYEE, user.roles) || may(ROLE_SUPERADMIN, user.roles)
                      ? false
                      : !!link[2].includes('uneditable');
                  const disableDelete =
                    may(ROLE_ROOT_EMPLOYEE, user.roles) || may(ROLE_SUPERADMIN, user.roles)
                      ? false
                      : !!link[2].includes('undeletable');
                  const disableMove = !link[2].includes('unmovable');
                  const disableUp = i === 0 ? true : false;
                  const disableDown = i === linksEdit.length - 1 ? true : false;
                  const disableTarget =
                    may(ROLE_ROOT_EMPLOYEE, user.roles) || may(ROLE_SUPERADMIN, user.roles)
                      ? false
                      : !link[2].includes('extern');
                  return (
                    <Row className="m-t-5">
                      <Col md={this.props.isWebsite && availablePages.length > 1 ? 3 : 6}>
                        <FormControl
                          type="text"
                          value={link[1]}
                          placeholder={messages.editor.click_me}
                          onChange={e => {
                            this.changeLink(e, i, 'name');
                          }}
                          disabled={disableEdit || disabled}
                        />
                      </Col>
                      {this.props.isWebsite && availablePages.length > 1 ? (
                        <Col md={this.props.isWebsite && availablePages.length > 1 ? 3 : 4}>
                          <FormAutocomplete
                            onChange={(e, value) => {
                              this.changeLink(value, i, 'type');
                            }}
                            className="no-padding no-margin"
                            value={link[3]}
                            name="type"
                            disabled={disableTarget || disableEdit || disabled}
                            options={types}
                          />
                        </Col>
                      ) : (
                        ''
                      )}
                      {!this.props.isWebsite || availablePages.length <= 1 || link[3] !== 'page' ? (
                        <Col md={4}>
                          <FormControl
                            type="text"
                            value={link[0]}
                            placeholder="https://www.creacheck.com"
                            onChange={e => {
                              this.changeLink(e, i, 'link');
                            }}
                            disabled={disableTarget || disableEdit || disabled}
                            onChange={e => {
                              this.changeLink(e, i, 'link');
                            }}
                          />
                        </Col>
                      ) : (
                        <Col md={4}>
                          <FormAutocomplete
                            onChange={(e, value) => {
                              this.changeLink(value, i, 'page');
                            }}
                            value={link[4]}
                            className="no-padding no-margin"
                            name="type"
                            disabled={disableTarget || disableEdit || disabled}
                            options={availablePages}
                          />
                        </Col>
                      )}
                      <Col md={2}>
                        <ButtonGroup>
                          {(disableMove || disabled) && (
                            <>
                              <Button disabled={disableUp || disabled} onClick={() => this.sortLink(i, 'up')}>
                                <i className="fas fa-angle-up" />
                              </Button>
                              <Button disabled={disableDown || disabled} onClick={() => this.sortLink(i, 'down')}>
                                <i className="fas fa-angle-down" />
                              </Button>
                            </>
                          )}
                          <SimplePrompt
                            showLabel={false}
                            className=""
                            bsSize=""
                            disabled={disableDelete || disabled}
                            onSuccess={e => {
                              this.removeLink(e, i);
                            }}
                          />
                        </ButtonGroup>
                      </Col>
                    </Row>
                  );
                })}
                <Row className="m-t-5">
                  <Col md={12}>
                    <Button onClick={this.addLink}>
                      <i className="fas fa-plus" />
                    </Button>
                  </Col>
                </Row>
                <Row className="m-t-20">
                  <Col md={12}>
                    <Button bsStyle="success" bsSize="large" onClick={this.setLinks}>
                      {messages.editor.take_on}
                    </Button>
                  </Col>
                </Row>
                <Button bsStyle="danger" bsSize="large" onClick={this.closeNavEdit} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavEntriesR: entries => {
      dispatch(setNavEntriesR(entries));
    },
  };
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    pages: state.editor.editionRV.pages,
    isWebsite: state.editor.editionRV?.template?.export_type?.value === '000000000000000000000003',
    editionId: state.editor.editionRV?.id,
    user: state.login.user,
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(NavigationElement)
  )
);
